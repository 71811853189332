// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-jsx": () => import("./../../../src/pages/404.jsx" /* webpackChunkName: "component---src-pages-404-jsx" */),
  "component---src-pages-blog-jsx": () => import("./../../../src/pages/blog.jsx" /* webpackChunkName: "component---src-pages-blog-jsx" */),
  "component---src-pages-case-study-cicd-integration-jsx": () => import("./../../../src/pages/case-study/CICD-integration.jsx" /* webpackChunkName: "component---src-pages-case-study-cicd-integration-jsx" */),
  "component---src-pages-case-study-salesforce-integration-jsx": () => import("./../../../src/pages/case-study/salesforce-integration.jsx" /* webpackChunkName: "component---src-pages-case-study-salesforce-integration-jsx" */),
  "component---src-pages-contact-us-jsx": () => import("./../../../src/pages/contact-us.jsx" /* webpackChunkName: "component---src-pages-contact-us-jsx" */),
  "component---src-pages-data-engineering-jsx": () => import("./../../../src/pages/data-engineering.jsx" /* webpackChunkName: "component---src-pages-data-engineering-jsx" */),
  "component---src-pages-index-jsx": () => import("./../../../src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-pages-products-ats-jsx": () => import("./../../../src/pages/products/ats.jsx" /* webpackChunkName: "component---src-pages-products-ats-jsx" */),
  "component---src-pages-products-timesheet-jsx": () => import("./../../../src/pages/products/timesheet.jsx" /* webpackChunkName: "component---src-pages-products-timesheet-jsx" */),
  "component---src-pages-salesforce-jsx": () => import("./../../../src/pages/salesforce.jsx" /* webpackChunkName: "component---src-pages-salesforce-jsx" */),
  "component---src-pages-salesforce-lead-js": () => import("./../../../src/pages/salesforce/lead.js" /* webpackChunkName: "component---src-pages-salesforce-lead-js" */),
  "component---src-pages-sap-jsx": () => import("./../../../src/pages/sap.jsx" /* webpackChunkName: "component---src-pages-sap-jsx" */),
  "component---src-pages-talent-acquisition-jsx": () => import("./../../../src/pages/talent-acquisition.jsx" /* webpackChunkName: "component---src-pages-talent-acquisition-jsx" */),
  "component---src-pages-team-js": () => import("./../../../src/pages/team.js" /* webpackChunkName: "component---src-pages-team-js" */),
  "component---src-templates-blog-details-js": () => import("./../../../src/templates/blog-details.js" /* webpackChunkName: "component---src-templates-blog-details-js" */)
}

