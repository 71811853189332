import { initializeApp, getApps } from "firebase/app";
// import { getAnalytics } from 'firebase/analytics'
// import { getFunctions } from "firebase/functions";
// console.log("Hello");
if (!getApps().length) {
  const config = {
    apiKey: "AIzaSyDnXw48vo9hP2Bak5c0NFZMT0ldRsT7Cuw",
    authDomain: "digitalbiztech9.firebaseapp.com",
    projectId: "digitalbiztech9",
    storageBucket: "digitalbiztech9.appspot.com",
    messagingSenderId: "635786623822",
    appId: "1:635786623822:web:919a081c83c4e66aacbc1f",
    measurementId: "G-745B8TXG8M",
  };

  // Initialize Firebase
  if (typeof window !== "undefined") {
    const app = initializeApp(config);
    // const functions = getFunctions(app);
  }
  // const analytics = getAnalytics(app)
}
